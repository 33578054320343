import React, { useState } from "react";
import { Modal, Form, Button, Alert } from "react-bootstrap";

interface FAQEditModalProps {
  show: boolean;
  type: string;
  onClose: () => void;
  editData: {
    question: string;
    answer: string;
    published: boolean;
    topicId: number | null;
  } | any;
  setEditData: React.Dispatch<
    React.SetStateAction<{
      question: string;
      answer: string;
      published: boolean;
      topicId: number | null;
    } | any>
  >;
  onSubmit: () => void;
  topics: { value: number; name: string }[]; // Array of topics
}

const FAQEditModal: React.FC<FAQEditModalProps> = ({
  show,
  onClose,
  editData,
  setEditData,
  onSubmit,
  type,
  topics,
}) => {
  const [errors, setErrors] = useState<{ question?: string; answer?: string }>({
    question: "",
    answer: "",
  });

  if (!editData) {
    return null; // If editData is null, do not render the modal
  }
  const handleTopicChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTopicId = parseInt(e.target.value);
    setEditData((prevData: any) => ({
      ...prevData!,
      topicId: selectedTopicId,
    }));
  };

  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditData((prevData: any) => ({
      ...prevData!,
      question: e.target.value,
    }));
  };

  const handleAnswerChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditData((prevData: any) => ({
      ...prevData!,
      answer: e.target.value,
    }));
  };

  const handlePublishedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditData((prevData: any) => ({
      ...prevData!,
      published: e.target.checked,
    }));
  };

  const handleFormSubmit = () => {
    const validationErrors: { question?: string; answer?: string } = {};

    // Validate question and answer fields
    if (!editData.question) {
      validationErrors.question = "Question is required.";
    }
    if (!editData.answer) {
      validationErrors.answer = "Answer is required.";
    }

    // If there are validation errors, update the state and prevent submission
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // If no errors, call the onSubmit prop
      setErrors({});
      onSubmit();
    }
  };

  const onModalClose = ()=> {
    onClose()
    setErrors({ question: "", answer: ""})
  }

  return (
    <Modal show={show} onHide={onModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>{type === "edit" ? "Edit FAQ" : "Add FAQ"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Topic Field */}
          {type === "edit" ? (
            <Form.Group controlId="editTopic">
              <Form.Label>Topic</Form.Label>
              <Form.Control
                type="text"
                value={topics.find((topic) => topic.value === editData.topicId)?.name || ""}
                readOnly
                disabled={type === "edit"}
              />
            </Form.Group>
          ) : (
            <Form.Group controlId="editTopic">
              <Form.Label>Topic</Form.Label>
              <Form.Select
                value={editData?.topicId || ""}
                onChange={handleTopicChange}
              >
                <option value="">Select a Topic</option>
                {topics.map((topic) => (
                  <option key={topic.value} value={topic.value}>
                    {topic.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}

          {/* Question Field */}
          <Form.Group controlId="editQuestion" className="mt-3">
            <Form.Label>Question</Form.Label>
            <Form.Control
              type="text"
              value={editData.question}
              onChange={handleQuestionChange}
              isInvalid={!!errors.question} // Show invalid styling if there's an error
            />
            {errors.question && <Form.Control.Feedback type="invalid">{errors.question}</Form.Control.Feedback>}
          </Form.Group>

          {/* Answer Field */}
          <Form.Group controlId="editAnswer" className="mt-3">
            <Form.Label>Answer</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={editData.answer}
              onChange={handleAnswerChange}
              isInvalid={!!errors.answer} // Show invalid styling if there's an error
            />
            {errors.answer && <Form.Control.Feedback type="invalid">{errors.answer}</Form.Control.Feedback>}
          </Form.Group>

          {/* Published Checkbox */}
          <Form.Group controlId="editPublished" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Published"
              checked={editData.published}
              onChange={handlePublishedChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      {/* Modal Footer with Save and Cancel buttons */}
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleFormSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FAQEditModal;
