import React, { useMemo } from "react";
import { OverlayTrigger, Table, Tooltip as BsTooltip, Button } from "react-bootstrap";
import { useTable } from "react-table";
import { TUserObj } from "./typescript";
import { useTableSorting } from "../../globals/TableFilterShorting/TableFieldShorting";
import { TbSortAscending, TbSortDescending } from "react-icons/tb";
import { PiArrowsDownUpBold } from "react-icons/pi";
import hideIcon from "../../assets/images/icons/hide-action.svg"
import showIcon from "../../assets/images/icons/show-action.svg"
import deleteIcon from "../../assets/images/icons/deleted.png"
import { LuArrowDownUp } from "react-icons/lu";
import { FaArrowDownShortWide } from "react-icons/fa6";
import { FaArrowUpWideShort } from "react-icons/fa6";
import { Link } from "react-router-dom";
import config from "../../utils/config";
import GlobalErrorAlert from "../../globals/GlobalErrorFunction";

type UserManagementTableProps = {
  data: TUserObj[];
  onEdit: (user: TUserObj) => void;
  changePassword: (id: any, email: string, name: string) => void;
  setFilterUpdate: any;
  filterUpdate: any;
  error: string;
  apiStatus: string;
};


const UserManagementTable: React.FC<UserManagementTableProps> = ({ data, changePassword, setFilterUpdate, filterUpdate, error, apiStatus }) => {
  const { handleTableSorting } = useTableSorting();
  const MoodleBaseUrl = localStorage.getItem('MoodleBaseUrl') || config.MOODLE_BASE_URL


  // Memoize the table columns for performance
  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "id", // Assuming 'id' is unique and can be used for the row number
        Cell: ({ row }: any) => row.index + 1 + filterUpdate.pageNumber * 10, // Display index + 1 for S.No
      },
      {
        Header: (
          <div className="d-flex align-items-center">
            <span
              onClick={() => handleTableSorting("userFirstName", setFilterUpdate)}
            >
              <span> First Name </span>
              <span>
                {filterUpdate.sortBy === "userFirstName" &&
                  filterUpdate.sortOrder === "asc" ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <BsTooltip>Sorted by First Name Ascending </BsTooltip>
                    }
                  >
                    <button className="btn btn-link p-0">
                      <FaArrowUpWideShort />
                    </button>
                  </OverlayTrigger>
                ) : filterUpdate.sortBy === "userFirstName" &&
                  filterUpdate.sortOrder === "desc" ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <BsTooltip>Sorted by First Name Descending </BsTooltip>
                    }
                  >
                    <button className="btn btn-link p-0">
                      <FaArrowDownShortWide />
                    </button>
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={<BsTooltip>Sort by First Name Ascending  </BsTooltip>}
                  >
                    <button className="btn btn-link p-0">
                      <LuArrowDownUp />
                    </button>
                  </OverlayTrigger>
                )}
              </span>
            </span>
          </div>
        ),
        accessor: "userFirstName",
        Cell: ({ value }: any) => {
          // Capitalize the first character
          return value.charAt(0).toUpperCase() + value.slice(1);
        },
      },
      {
        Header: (
          <div className="d-flex align-items-center">
            <span
              onClick={() => handleTableSorting("userLastName", setFilterUpdate)}
            >
              <span> Last Name </span>
              <span>
                {filterUpdate.sortBy === "userLastName" &&
                  filterUpdate.sortOrder === "asc" ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <BsTooltip>Sorted by Last Name Ascending </BsTooltip>
                    }
                  >
                    <button className="btn btn-link p-0">
                      <FaArrowUpWideShort />

                    </button>
                  </OverlayTrigger>
                ) : filterUpdate.sortBy === "userLastName" &&
                  filterUpdate.sortOrder === "desc" ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <BsTooltip>Sorted by Last Name Descending </BsTooltip>
                    }
                  >
                    <button className="btn btn-link p-0">
                      <FaArrowDownShortWide />
                    </button>
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={<BsTooltip>Sort by Last Name Ascending  </BsTooltip>}
                  >
                    <button className="btn btn-link p-0">
                      <LuArrowDownUp />
                    </button>
                  </OverlayTrigger>
                )}
              </span>
            </span>
          </div>
        ),
        accessor: "userLastName",

      },
      {
        Header: (
          <div className="d-flex align-items-center">
            <span
              onClick={() => handleTableSorting("userEmail", setFilterUpdate)}
            >
              <span> Email </span>
              <span>
                {filterUpdate.sortBy === "userEmail" && filterUpdate.sortOrder === "asc" ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <BsTooltip>Sorted by Email Ascending</BsTooltip>
                    }
                  >
                    <button className="btn btn-link p-0">
                      <FaArrowUpWideShort />
                    </button>
                  </OverlayTrigger>
                ) : filterUpdate.sortBy === "userEmail" && filterUpdate.sortOrder === "desc" ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <BsTooltip>Sorted by Email Descending</BsTooltip>
                    }
                  >
                    <button className="btn btn-link p-0">
                      <FaArrowDownShortWide />
                    </button>
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={<BsTooltip>Sort by Email Ascending</BsTooltip>}
                  >
                    <button className="btn btn-link p-0">
                      <LuArrowDownUp />
                    </button>
                  </OverlayTrigger>
                )}
              </span>
            </span>
          </div>
        ),
        accessor: "userEmail",
        Cell: ({ row }: any) => (
          <OverlayTrigger
            placement="top"
            overlay={<BsTooltip>{"View Profile"}</BsTooltip>}
          // trigger={['hover', 'focus']}
          >
            {row.original.idNumber ?
              <Link
                className="action-icons"
                to={`${MoodleBaseUrl}/user/profile.php?id=${row.original.idNumber}`}
              >
                {row.original.userEmail}
              </Link> : row.original.userEmail}
          </OverlayTrigger>
        ),
      },


      {
        Header: "Moodle UserId",
        accessor: "idNumber",
      },
      {
        Header: "Status",
        accessor: (row: any) => {
          // Check the conditions for status display
          if (row.deleted === true) {
            return 'deleted';  // Red cross
          } else if (row.enabled === true && row.deleted === false) {
            return 'enabled';  // Hash symbol
          } else if (row.enabled === false && row.deleted === false) {
            return 'hash';  // Green check
          }
          return 'hash';  // Default case if none of the conditions match
        },
        Cell: ({ value }: any) => {
          if (value === 'deleted') {
            return <OverlayTrigger
              placement="top"
              overlay={
                <BsTooltip>User is deleted</BsTooltip>
              }
            >
              <img src={deleteIcon} alt="User is Deleted" />
            </OverlayTrigger>
          } else if (value === 'hash') {
            return <OverlayTrigger
              placement="top"
              overlay={
                <BsTooltip>User is Suspended</BsTooltip>
              }
            >
              <img src={hideIcon} alt="User is Suspended" />
            </OverlayTrigger>
          } else if (value === 'enabled') {
            return <OverlayTrigger
              placement="top"
              overlay={
                <BsTooltip>User is Active</BsTooltip>
              }
              
            >
              <img src={showIcon} alt="User is Active" />
            </OverlayTrigger>

          }
          return null;  // Return nothing if no condition matched
        },
      },
      {
        Header: "Actions",
        Cell: ({ row }: any) => (
          row.original.deleted !== true ? (
            <div>
              <Button
                variant="link"
                size="sm"
                onClick={() => changePassword(row.original.userId, row.original.userEmail, `${row.original.userFirstName + " " + row.original.userLastName}`)}
              >
                Change Password
              </Button>
            </div>
          ) : null // Optionally render nothing if the condition is not met
        ),
      },
    ],
    [data]
  );

  // Use the react-table hook to manage the table state
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<any>({
    columns,
    data,
  });


  return (
    <div className="admin-table-wrapper">
      <Table responsive className="generaltable table-dark-header" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <th {...column.getHeaderProps()} key={index}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length > 0 ? (
            rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index} className="custom-row">
                  {row.cells.map((cell, index) => (
                    <td {...cell.getCellProps()} key={index}>
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })
          ) : (

<>
  {apiStatus === "true" ? (
    <tr>
      <td colSpan={6} className="text-center">{"Loading..."}</td>
    </tr>
  ) : Object.keys(error).length > 0 ? (
    <GlobalErrorAlert error={error} holdFlag={true} />
  ) : (
    <tr>
      <td colSpan={6} className="text-center">{"No users found"}</td>
    </tr>
  )}
</>

          )}
        </tbody>
      </Table>
    </div>
  );
};

export default UserManagementTable;
