import { useState, useEffect, useCallback } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
// import alertIcon from "../../../assets/images/icons/bell-icon.svg";
import { IoSettingsOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Nav } from 'react-bootstrap';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getData } from '../../../../adapters/moodleServices';
import config from '../../../../utils/config';

function NotificationOverlay() {

  const isAdmin = useSelector((state: any) => state.user.isAdmin);
  const location: any = useLocation();
  const hitApiOnDashbord = location.pathname == '/dashboard';
  const { t } = useTranslation();
  // const selectedLanguages = localStorage.getItem('selectedLanguages') && JSON.parse(localStorage.getItem('selectedLanguages'))
  const selectedLanguages = "en"
  const id = useSelector((state: any) => state.user.userId);
  // const useridto = useSelector((state: any) => state.user.userId);
  const token = localStorage.getItem('Wstoken')
  const MoodleBaseUrl = localStorage.getItem('MoodleBaseUrl') || config.MOODLE_BASE_URL

  const [message, setMessage] = useState<{
    totalcount: number,
    list: any[],
  }>({
    totalcount: 0,
    list: [],
  });
  const fetchNotifications = () => {
    const query = {
      wsfunction: 'message_popup_get_popup_notifications',
      useridto: id
    };
    getData(query)
      .then(res => {
        if (res.status === 200 && res.data) {
          if (res.data.errorcode) {
            console.log('Something went wrong');
          } else {
            const notifications = res.data.notifications.filter((item: { deleted: any; }) => !item.deleted);
            const count = res.data.unreadcount;
            setMessage({
              totalcount: count,
              list: notifications,
            });
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  useEffect(() => {
    hitApiOnDashbord && fetchNotifications(); // Initial fetch
    // const intervalId = setInterval(() => {
    //   fetchNotifications(); // Fetch every hour
    // }, 60 * 60 * 1000); // 60 minutes * 60 seconds * 1000 milliseconds

    // return () => clearInterval(intervalId); // Clean up on unmount
  }, [id]); // Dependency array includes useridto

  const handleReadAllNotification = useCallback(() => {
    if (message.totalcount === 0) return; // Early exit if no notifications

    const query = {
      wsfunction: 'core_message_mark_all_notifications_as_read',
      useridto: id,
      token: token
    };
    axios
      .post(
        `${MoodleBaseUrl}/webservice/blrest/server.php?wsfunction=${query.wsfunction}&wstoken=${query.token}&moodlewsrestformat=json&useridto=${query.useridto}`
      )
      .then((res) => {
        if (res) {
          fetchNotifications(); // Fetch notifications only if the response is valid
        }
      })
      .catch((error) => {
        console.error("Failed to mark notifications as read:", error);
      });
  }, [id, token, message.totalcount, fetchNotifications]);

  return (
  <>
    <div className="primary-navigation">
      <ul className="nav more-nav navbar-nav">
        <li className="nav-item">
          <a className="nav-link" href="/dashboard">Dashboard</a>
        </li>
        {isAdmin &&
          <li className="nav-item">
            <a className="nav-link" href={`${MoodleBaseUrl}/admin/search.php`}>Site administration</a>
          </li>
        }
      </ul>
    </div>

    <Nav as="ul" className="sh-toolbar">
      <Nav.Item as="li" className="sh-notification">
        <OverlayTrigger
          trigger={'click'}
          placement="bottom"
          overlay={
            <Popover id="popover-basic" className='alert-popover'>
              <Popover.Header as="h3">
                <div className='d-flex justify-content-between items-center'>
                  {t('common.notification')}
                  <div>
                    <span className='me-2 checkStatus' onClick={handleReadAllNotification}><FaCheck /></span>
                    <a className='checkStatus' href={`${MoodleBaseUrl}/message/notificationpreferences.php`}><IoSettingsOutline /></a>
                  </div>
                </div>
              </Popover.Header>
              <Popover.Body className='alert-popover-body'>
                <div className='all-notifications'>
                  {message.list.length > 0 ? (
                    message.list.map((item, index) => (
                      <div key={item.id} className={`content-item-container notification ${!item.read ? "unread" : ""}`}>
                        <a className='context-link' href={`${MoodleBaseUrl}/message/output/popup/notifications.php?notificationid=${item.id}&offset=0&lang=${selectedLanguages}`}>
                          <div className='content-item-body'>
                            <div className='notification-image'><img src={item.iconurl} alt="" /></div>
                            <div className='notification-message'>{item.subject}</div>
                          </div>
                          {/* <div key={item.id} style={{backgroundColor: !item.read ? "unread" : ""}}> */}
                          <div className='content-item-footer'>
                            <div className='timestamp'>{item.timecreatedpretty}</div>
                          </div>
                          <a className='view-more' href={`${MoodleBaseUrl}/message/output/popup/notifications.php?notificationid=${item.id}&offset=0&lang=${selectedLanguages}`}>{t('common.viewFullNotif')}</a>
                        </a>
                      </div>
                    ))
                  ) : (
                    <p>{t('common.noNewNotif')}</p>
                  )}
                </div>
              </Popover.Body>
              <div className='seeAlert'>
                <a className='btn btn-link' href={`${MoodleBaseUrl}/message/output/popup/notifications.php`} >See all</a>
              </div>
            </Popover>
          }
          rootClose
        >
          <div className='notification-icon'>
            <FontAwesomeIcon icon={faBell} />
            {message.totalcount > 0 && <span>{message.totalcount}</span>}
          </div>
        </OverlayTrigger>
      </Nav.Item>
    </Nav>
  </>
  );
}
export default NotificationOverlay;
