import { useState, useEffect } from "react";
import TimerAlertBox from "../../widgets/alert/timerAlert";
import Swal from "sweetalert2";

const GlobalErrorAlert: any = ({ error, holdFalg }: any) => {
    const [showAlert, setShowAlert] = useState(true); // State to control alert visibility
    const [isLoggingOut, setIsLoggingOut] = useState(false); // State to handle logout rendering
    const [message, setMessage] = useState("Server error occurred"); // Default error message
    const [alertBoxColor, setAlertBoxColor] = useState("danger"); // Default color for error alerts

    console.log(error);

    useEffect(() => {
        if (error?.response?.status || error?.status) {
            let newMessage = "Server error occurred";
            let newAlertBoxColor = "danger";

            switch (error.response?.status || error.status) {
                case 200:
                    newMessage = "Request was successful";
                    newAlertBoxColor = "success";
                    break;
                case 400:
                    newMessage =
                        error.response?.data?.message ||
                        "Something went wrong with your request. Please check the information you entered and try again.";
                    break;
                case 401:
                    newMessage =
                        error.response?.data?.message ||
                        "You are not logged in or your session has expired. Please log in and try again.";
                    setIsLoggingOut(true);
                    break;
                case 403:
                    newMessage =
                        error.response?.data?.message ||
                        "You do not have permission to perform this action. Please contact support if you believe this is a mistake.";
                    break;
                case 404:
                    newMessage =
                        error.response?.data?.message ||
                        "We couldn't find what you were looking for. Please check and try again.";
                    break;
                case 500:
                    newMessage =
                        error.response?.data?.message ||
                        "Something went wrong on our end. Please try again later.";
                    break;
                case 502:
                    newMessage =
                        error.response?.data?.message ||
                        "The server is currently unavailable. Please try again in a few moments.";
                    break;
                default:
                    newMessage =
                        error.response?.data?.message ||
                        "An unexpected error occurred. Please try again or contact support if the issue persists.";
                    break;
            }

            setMessage(newMessage);
            setAlertBoxColor(newAlertBoxColor);
        }
    }, [error]);

    useEffect(() => {
        if (!holdFalg) {
            const timer = setTimeout(() => setShowAlert(false), 4000);
            return () => clearTimeout(timer);
        }
    }, [holdFalg]);

    useEffect(() => {
        if (isLoggingOut) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: message,
                showConfirmButton: false,
                // footer: '<a href="#">Why do I have this issue?</a>'
              });
            const timer = setTimeout(() => {
                // Redirect or perform logout here
                window.location.href = "/logout"; // Example: redirect to logout
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [isLoggingOut]);

    return (
        showAlert && (
            <TimerAlertBox
                alertMsg={message}
                className="mt-3"
                variant={alertBoxColor}
                showAlert={showAlert}
            />
        )
    );
};

export default GlobalErrorAlert;
