import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { postData } from '../../adapters/coreservices';
import Swal from 'sweetalert2';
import GlobalErrorAlert from '../../globals/GlobalErrorFunction';
import { useTranslation } from 'react-i18next';

interface FAQItem {
  id?: number;
  moodleconfigkey: string;
  moodleconfigvalue: string;
}

interface AddFaqTopicModalProps {
  show: boolean;
  handleClose: () => void;
  initialData?: any;
}

function AddFaqTopicModal({ show, handleClose, initialData }: AddFaqTopicModalProps) {
 
 const [aipError, setAipError] =useState("")
  const [formData, setFaqItem] = useState<FAQItem>({
    moodleconfigkey: '',
    moodleconfigvalue: '',
  });

  const { t } = useTranslation();

  // Sync formData with initialData whenever it changes
  useEffect(() => {
    setFaqItem(initialData || { label:"", moodleconfigkey: '', moodleconfigvalue: '' });
  }, [initialData]);
  console.log(initialData);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFaqItem({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setAipError("")
    const data = formData || {};

      postData('/moodle/config/save',   [{
        "moodleconfigkey": data.moodleconfigkey,
        "moodleconfigvalue": data.moodleconfigvalue
    }], undefined)
      .then((response) => {
        if (response.status === 200) {
        handleClose();
          Swal.fire({
            timer: 3000,
            width: "25em",
            color: "#666",
            icon: "success",
            background: "#e7eef5",
            showConfirmButton: false,
            text: `URL ${t("common.added")}`,
          });
        }
      })
      .catch((err) => {
        setAipError(err || 'An error occurred.');
      });
      
  };

  const heandleOnHide = () => {
    setFaqItem({ moodleconfigkey: '', moodleconfigvalue: '' });
    setAipError("")
    handleClose();
  };

  return (
    <Modal show={show} onHide={heandleOnHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {initialData ? `Edit  ${initialData.label}` : 'Add New URL'}
        </Modal.Title>
      </Modal.Header>
     {aipError && < GlobalErrorAlert error={aipError} />}
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="faqUrl">
            <Form.Label>URL</Form.Label>
            <Form.Control
              type="text"
              name="moodleconfigvalue"
              value={formData.moodleconfigvalue} // Bind to formData
              onChange={handleChange} // Update formData on change
              placeholder="Enter URL"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddFaqTopicModal;
