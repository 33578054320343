import $ from "jquery";
    
$(document).on("click","#verticalMainMenuCollapse .hamburger",function() {
    $('.mainmenu-container, .global-container, .mainMenuToggle, .global-header, #page-footer').addClass('active');
    $('.hamburger').addClass('d-none');
    $('.chevrondown-par').removeClass('d-none');
    $('.secondary-logo').addClass('d-block');
    $('.mh-logo').addClass('d-none');
});

$(document).on("click",".chevrondown",function() {
    $('.mainmenu-container, .global-container, .mainMenuToggle, .global-header, #page-footer').removeClass('active');
    $('.hamburger').removeClass('d-none');
    $('.chevrondown-par').addClass('d-none');
    $('.secondary-logo').removeClass('d-block');
    $('.mh-logo').removeClass('d-none');
    $('.cus-todo-block').removeClass('d-block');
    // $('.cus-todo-block').addClass('d-none');
    $('.cus-bell-box').removeClass('d-none');
});

$(document).on("click","#bellBox, .cus-doto",function() {
    if(!$('#verticalMainMenu, #verticalMainMenuCollapse, .mainMenuToggle').hasClass('active')){
        $('#verticalMainMenu, #verticalMainMenuCollapse, .mainMenuToggle, .mainmenu-container, .global-container, .global-header, #page-footer').addClass('active');
        $('.secondary-logo').toggleClass('d-block');
        $('.cus-bell-box').removeClass('d-block');
        $('.hamburger, .mh-logo, .chevrondown-par').toggleClass('d-none');  
    }
    $('.global-container, .cus-todo-block, .cus-todo-block').toggleClass('d-block');           
    $('.cus-bell-box').toggleClass('d-none');

});
