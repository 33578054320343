import axios, { AxiosInstance } from 'axios';
import config from '../../utils/config'; 

type RequestData = Record<string, any>;

// const instance = axios.create({
//   baseURL: localStorage.getItem('MoodleBaseUrl') || config.MOODLE_BASE_URL,
// });

const publicTokin = localStorage.getItem('publicWebServiceToken') || "";

let instance: AxiosInstance = axios.create({
  baseURL: localStorage.getItem('MoodleBaseUrl') || config.MOODLE_BASE_URL || 'https://saral.learn.ballisticlearning.com',
  headers: {
    'Content-Type': 'application/json',
  },
});

const waitForMoodleBaseUrl = async (): Promise<string> => {
  while (!localStorage.getItem('MoodleBaseUrl')) {
    await new Promise((resolve) => setTimeout(resolve, 100)); // Wait 100ms before retrying
  }
  return localStorage.getItem('MoodleBaseUrl') || '';
};

const updateAxiosInstance = async (): Promise<void> => {
  const baseUrl = await waitForMoodleBaseUrl();
  instance = axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};


// Listen for changes to `MoodleBaseUrl` in localStorage
window.addEventListener('storage', (event) => {
  if (event.key === 'MoodleBaseUrl') {
    updateAxiosInstance();
  }
});

// Ensure MoodleBaseUrl is available before API calls
const ensureMoodleBaseUrl = async (): Promise<void> => {
  await waitForMoodleBaseUrl(); // Wait until MoodleBaseUrl is available
};


const waitForToken = async (): Promise<string> => {
  while (!localStorage.getItem('Wstoken') && !config.WSTOKEN) {
    await new Promise(resolve => setTimeout(resolve, 100)); // Wait 100ms before retrying
  }
  return localStorage.getItem('Wstoken') || config.WSTOKEN;
};

const waitForPublicToken = async (): Promise<string> => {
  while (!localStorage.getItem('publicWebServiceToken')) {
    await new Promise((resolve) => setTimeout(resolve, 100)); // Wait 100ms before retrying
  }
  return localStorage.getItem('publicWebServiceToken') || '';
};

const getPublicData = async (requestData: RequestData, isLoggedIn: boolean) => {
  await ensureMoodleBaseUrl();
  const query = { ...requestData };

  if (query.loginrequest) {
    query.service = 'moodle_mobile_app';

    if (!config.TOKEN_ENDPOINT) {
      throw new Error('TOKEN_ENDPOINT is not defined');
    }

    return instance.get(config.TOKEN_ENDPOINT, { params: query });
  }

  query.wstoken = await waitForPublicToken();
  query.moodlewsrestformat = 'json';

  if (!config.REST_ENDPOINT) {
    throw new Error('REST_ENDPOINT is not defined');
  }

  return instance.get(config.REST_ENDPOINT, { params: query });
};


const getData = async (requestData: RequestData) => {
  await ensureMoodleBaseUrl();
  const query = { ...requestData };
  query.wstoken = await waitForToken();
  query.moodlewsrestformat = 'json';
  if (!config.REST_ENDPOINT) {
    throw new Error("REST_ENDPOINT is not defined");
  }
  return instance.get(config.REST_ENDPOINT, { params: query });
};

const postData = async (requestData: RequestData) => {
  await ensureMoodleBaseUrl();
  const query = { ...requestData };
  query.wstoken = await waitForToken();
  query.moodlewsrestformat = 'json';
  if (!config.REST_ENDPOINT) {
    throw new Error("REST_ENDPOINT is not defined");
  }
  return instance.post(config.REST_ENDPOINT, query);
};

const signupData = async (requestData: RequestData) => {
  await ensureMoodleBaseUrl();
  const query = { ...requestData };
  query.wstoken = config.SIGNUP_TOKEN;
  query.moodlewsrestformat = 'json';
  if (!config.REST_ENDPOINT) {
    throw new Error("REST_ENDPOINT is not defined");
  }
  return instance.get(config.REST_ENDPOINT, { params: query });
};

const putData = async (requestData: RequestData) => {
  await ensureMoodleBaseUrl();
  const query = { ...requestData };
  query.wstoken = await waitForToken();
  query.moodlewsrestformat = 'json';
  if (!config.REST_ENDPOINT) {
    throw new Error("REST_ENDPOINT is not defined");
  }
  return instance.put(config.REST_ENDPOINT, query);
};

const deleteData = async (requestData: RequestData) => {
  await ensureMoodleBaseUrl();
  const query = { ...requestData };
  query.wstoken = await waitForToken();
  query.moodlewsrestformat = 'json';
  if (!config.REST_ENDPOINT) {
    throw new Error("REST_ENDPOINT is not defined");
  }
  return instance.delete(config.REST_ENDPOINT, { data: query });
};

const processQuizData = async (requestData: RequestData) => {
  await ensureMoodleBaseUrl();
  const query = { ...requestData };
  const wstoken = await waitForToken();
  const paramString = `wsfunction=${query.wsfunction}&wstoken=${wstoken}&moodlewsrestformat=json&attemptid=${query.attemptid}&finishattempt=${query.finishattempt}&${query.quizdata}`;
  return instance.get(`${config.REST_ENDPOINT}?${paramString}`);
};

const getCalendarEvents = async (requestData: RequestData) => {
  await ensureMoodleBaseUrl();
  const query = { ...requestData };
  const wstoken = await waitForToken();
  const paramString = `wsfunction=${query.wsfunction}&wstoken=${wstoken}&moodlewsrestformat=json&${query.dataParam}`;
  return instance.get(`${config.REST_ENDPOINT}?${paramString}`);
};

export {
  getPublicData, getData, postData, putData, deleteData, processQuizData, signupData, getCalendarEvents
};
