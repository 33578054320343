import React, { useEffect, useState } from 'react'
import { getData } from '../../adapters/moodleServices';
import Dashboard from './dashboard';
import { useSelector } from 'react-redux';

function DashboardApi() {

  const [loading, setLoading] = useState<string>("true");
  const dummyDashboard = {
    "pagetitle": "Dashboard",
    "dashboard": []
  }
  const [dashboardData, setDashboardData] = useState<any | null>(dummyDashboard);

  // redux state 
  const userid = useSelector((state: any) => state.user.userId);
  // const userid = 2;


  useEffect(() => {
    setLoading("true")
    const query = {
      wsfunction: 'local_seatcreation_get_dashboard_tabs',
      userid
    };

    getData(query)
      .then(res => {
        if (res.status === 200 && res.data) {
          if (res.data.errorcode) {
            setDashboardData(dummyDashboard)
            setLoading("false");
          } else {
            setDashboardData(res.data);
            setLoading("false");
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, [userid]);

  return (
    <Dashboard dashboardData={dashboardData} loading={loading}/>
  )
}

export default DashboardApi