import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { postData } from '../../../../adapters/coreservices';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

interface FAQItem {
  id?: number;
  name: string;
  published: boolean;
}

interface AddFaqTopicModalProps {
  edit: FAQItem | null| any; // Hold data when editing a topic
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onTopicAdded: () => void; // Callback when topic is added
  moodlaStatus: string; 
}

const AddFaqTopicModal: React.FC<AddFaqTopicModalProps> = ({
  edit,
  showModal,
  setShowModal,
  onTopicAdded,
  moodlaStatus
}) => {
  const [newTopic, setNewTopic] = useState<FAQItem>({
    id: undefined,
    name: '',
    published: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    if(moodlaStatus === "edit" && edit && edit.id) {
      setNewTopic({
        id: edit.id,
        name: edit.topic || '',
        published: edit.published || false,
      });
    } else {
      setNewTopic({
        id: undefined,
        name: '',
        published: false,
      });
    }
  }, [showModal]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setNewTopic({
      ...newTopic,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleClose = () => {
    setShowModal(false);
    setNewTopic({ id: undefined, name: '', published: false });
    setError('');
  };

  const handleSubmit = () => {
    if (!newTopic.name.trim()) {
      setError(t('common.topicNameRequired')); // Display error if name is empty
      return;
    }

    setIsLoading(true);

    postData('faq/create-category', newTopic, undefined)
      .then((response) => {
        if (response.status === 201) {
          onTopicAdded(); // Refresh FAQ data
          handleClose();
          Swal.fire({
            timer: 3000,
            width: "25em",
            color: "#666",
            icon: "success",
            background: "#e7eef5",
            showConfirmButton: false,
            text: t('common.topicAdded'),
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err || 'An error occurred.');
        setIsLoading(false);
      });
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{edit ? 'Edit FAQ Topic' : 'Add FAQ Topic'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* {error && <div className="alert alert-danger">{error}</div>} */}
        <Form>
          <Form.Group controlId="formTopicName">
            <Form.Label>Topic Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={newTopic.name}
              onChange={handleInputChange}
              placeholder="Enter Topic Name"
              isInvalid={!!error}
            />
            <Form.Control.Feedback type="invalid">
              {t('common.topicNameRequired')}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formTopicPublished" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Published"
              name="published"
              checked={newTopic.published}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? 'Submitting...' : edit ? 'Save Changes' : 'Submit'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddFaqTopicModal;
