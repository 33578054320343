import React, { useMemo } from 'react';
import { Table, Button, Alert } from 'react-bootstrap';
import GlobalErrorAlert from '../../../../globals/GlobalErrorFunction';

interface FAQItem {
  id: number;
  name: string;
  published: boolean;
  faqs: any[];  // Assuming `faqs` is now an empty array or doesn't need to be used
}

// interface FAQData {
//   pager: {
//     totalPages: number;
//     totalElements: number;
//   };
//   items: FAQItem[];
// }

interface AddFaqTopicProps {
  faqData: any;
  isLoading: string;
  error: string;
  handleEdit:any;
  handleDelete:any;
  pageNumber: number;
}

const AddFaqTopic: React.FC<AddFaqTopicProps> = ({ faqData, isLoading, error, handleEdit,handleDelete,pageNumber }) => {

  const tableData = useMemo(() => {
    let indexCounter = 0  ;
    return faqData.map((item:any) => ({
      sno: ++indexCounter,
      topic: item.name, // Only using item.name (the topic name)
      id: item.id, // Use FAQItem id for action handling
      published: item.published
    }));
  }, [faqData]);


  const columns = useMemo(
    () => [
      { Header: 'S.No', accessor: 'sno' },
      { Header: 'Topic', accessor: 'topic' },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }: any) => (
          <div className="d-flex gap-2">
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleEdit(row)}
            >
              Edit
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => handleDelete(row.id)}
            >
              Delete
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <div>
        <Table responsive className="generaltable table-dark-header">
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th key={index}>{col.Header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.length > 0 ? (
              tableData.map((row:any, index:any) => (
                <tr key={index}>
                  {columns.map((col, colIndex) => (
                    <td key={colIndex}>
                      {col.accessor === 'action'
                        ? col.Cell?.({ row })
                        : row[col.accessor as keyof typeof row]}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <>
              {isLoading === "true" ? (
                <tr>
                  <td colSpan={6} className="text-center">{"Loading..."}</td>
                </tr>
              ) : Object.keys(error).length > 0 ? (
                <GlobalErrorAlert error={error} holdFalg={true} />
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">{"No users found"}</td>
                </tr>
              )}
            </>
            )}
          </tbody>
        </Table>
    </div>
  );
};

export default AddFaqTopic;
