const API_URL = process.env.REACT_API_URL;

// Fetch token directly from localStorage
const wsToken = localStorage.getItem('Wstoken');
const MoodleBaseUrl = localStorage.getItem('MoodleBaseUrl')

const config = {
  APP_NAME: process.env.REACT_APP_NAME,
  MOODLE_BASE_URL: MoodleBaseUrl || process.env.REACT_APP_MOODLE_BASE_URL,
  // MOODLE_BASE_URL: "",
  REST_ENDPOINT: process.env.REACT_APP_REST_ENDPOINT,
  TOKEN_ENDPOINT: process.env.REACT_APP_TOKEN_ENDPOINT,
  OAUTH2_URL: process.env.REACT_APP_OAUTH2_URL,
  JAVA_API_URL: process.env.REACT_APP_JAVA_API_URL,
  CORE_SERVICE_URL: process.env.REACT_APP_CORE_SERVICE_URL,
  HOSTEL_SERVICE_URL: process.env.REACT_APP_HOSTEL_SERVICE_URL,
  WSTOKEN: "",  // Directly using the value from localStorage
  SIGNUP_TOKEN: process.env.REACT_APP_SIGNUP_TOKEN,
  ADMIN_MOODLE_TOKEN: process.env.REACT_APP_ADMIN_MOODLE_TOKEN,
  OAUTH2_ACCESS_TOKEN: process.env.REACT_APP_OAUTH2_ACCESS_TOKEN,
  REDIRECT_URI: `${window.location.protocol}//${window.location.host}/authlogin`
};

export default config;
