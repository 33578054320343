import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FaqPage from './FAQ';
import axios from 'axios';
import GlobalErrorAlert from '../../../../globals/GlobalErrorFunction';

interface FAQ {
    id: number;
    question: string;
    answer: string;
    published: boolean;
}

interface Item {
    id: number;
    name: string;
    published: boolean;
    faqs: FAQ[];
}

interface FAQData {
    pager: {
        totalPages: number;
        totalElements: number;
    };
    items: Item[];
}

// interface errType {
//     message: string;
//     status: number;
// }

function FAQ() {
    const { t } = useTranslation();
    const [filterUpdate, setFilterUpdate] = useState({
        pageNumber: 0,
        pageSize: 10,
    });
    const dummyData = { "pager": {}, "items": [] };
    const [faqData, setFaqData] = useState<FAQData | any>(dummyData);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>("");

    useEffect(() => {
        // Ensure `filterUpdate` is passed correctly as query params
        setIsLoading(true);
        axios
            .get('https://api.saral.ballisticlearning.com/core-service/api/v1/public/get-all-faqs', {
                params: filterUpdate,  // Correct way to pass query parameters
            })
            .then((result: any) => {
                if (result.data !== '' && result.status === 200) {
                    setFaqData(result.data);
                }
                setIsLoading(false);
            })
            .catch((err: any) => {
                setError(err); // Set error state when an error occurs
                setFaqData(dummyData); // Reset data to dummy state if fetch fails.
                setIsLoading(false);
            });
    }, [filterUpdate]);

    return (
        <div className="contentarea-wrapper">
            <h2 className="page-title">{t('FAQ.faqTitle')}</h2>
            <FaqPage faqData={faqData} isLoading={isLoading} />

            {/* Show the GlobalErrorAlert component when there is an error */}
          {error &&  <GlobalErrorAlert error={error} />}
        </div>
    );
}

export default FAQ;
