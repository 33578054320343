import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddFaqTopicModal from './AddConfigUrlModal';
import { getData } from '../../adapters/coreservices';
import PageTitle from '../../widgets/pageTitle';
import AddConfigTable from './ConfigUrlTable';

interface FAQItem {
  id?: number;
  name: string;
  url: string;
  icon: string;
  published: boolean;
}

interface FAQData {
  pager: {
    totalPages: number;
    totalElements: number;
  };
  items: FAQItem[];
}

function ConfigUrlIndex() {
  const { t } = useTranslation();
  const [faqData, setFaqData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState<FAQItem | null>(null);

  const fetchUrlData = async () => {
    setIsLoading(true);
    setError("")
    try {
      const result = await getData('/moodle/config/moodle-values');
      if (result.data && result.status === 200) {
        setFaqData(result.data);
      }
    } catch (err) {
      setError('Failed to load URLs');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUrlData();
  }, []);

  const handleEdit = (data: FAQItem) => {
    setEdit(data);
    setShowModal(true);
  };

  return (
    <div className="contentarea-wrapper">
      <PageTitle pageTitle={t('Moodle URL Configuration')} gobacklink="/lmsconfig" />

      <AddConfigTable
        faqData={faqData}
        isLoading={isLoading}
        error={error}
        handleEdit={handleEdit}
      />

      <AddFaqTopicModal
        show={showModal}
        handleClose={() => {
          setShowModal(false);
          fetchUrlData();
        }}
        initialData={edit || undefined}
      />
    </div>
  );
}

export default ConfigUrlIndex;
