// src/components/HeaderFooterLayout.tsx

import { useLocation, Outlet } from 'react-router-dom';
import './header.scss';
import chevrondown from "../../../assets/images/chevrondown.png";
import logowhite from "../../../assets/images/logowhite.png";
import logo from "../../../assets/images/logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import '../../../assets/js/custom.js';
import { Dropdown } from "react-bootstrap";
import { useSelector } from 'react-redux';
import config from '../../../utils/config';
import { getInitial } from '../../../globals/initialLetterofname/firstletterOfFirstOrLastName';
import { useState } from 'react';
import ChangePassword from '../../changePassword/changePassword';
import NotificationOverlay from './SidebarOrHeaderContent/notifications';
import SidebarContent from './SidebarOrHeaderContent/SidebarContent';
interface HeaderFooterLayoutProps {
}

const HeaderFooterLayout: React.FC<HeaderFooterLayoutProps> = () => {
  const location = useLocation();

  // Define paths where the header/footer should be hidden
  const hideHeaderFooterPaths = ['/', '/authlogin', '/logout'];
  const showHeaderFooter = !hideHeaderFooterPaths.includes(location.pathname);

  // Redux state
  const isAdmin = useSelector((state: any) => state.user.isAdmin);
  const isLoggedIn = useSelector((state: any) => state.user.loggedIn);
  const userInfo = useSelector((state: any) => state.user.userInfo);

  // Local state
  const [showModal, setShowModal] = useState<any>(false);
  const MoodleBaseUrl = localStorage.getItem('MoodleBaseUrl') || config.MOODLE_BASE_URL


  return (
    <>
      {/* header start  */}{
      showHeaderFooter &&
        <div className="fixed-top main-header">
          <div id="verticalMainMenuCollapse" className="mainMenuToggle">
            <button type="button" className="btn btn-link hamburger" data-toggle="tooltip" data-placement="right" title="Toggle sidebar" >
              <FontAwesomeIcon icon={faBars} />
            </button>
            <button type="button" className="btn btn-link chevrondown-par d-none" data-toggle="tooltip" data-placement="right" title="Toggle sidebar" >
              <div className="chevrondown">
                <img src={chevrondown} alt="chevrondown" className="img-fluid" />
              </div>
            </button>
            <div className="secondary-logo">
              <a href="#"><img src={logowhite} alt="{{sitename}}" /></a>
            </div>
          </div>

          <nav className="navbar navbar-light bg-white navbar-expand global-header" aria-label="{{#str}}sitemenubar, admin{{/str}}">
            <div className="container-fluid">
              <div className="nav-box mh-logo">
                <a href="/dashboard"><img src={logo} alt="Logo" /></a>
              </div>
              {isLoggedIn &&
                <div className="nav-box ms-auto mh-usermenu">
                  {/* Header Notification  */}
                  {isLoggedIn &&
                    <NotificationOverlay />
                  }

                  <Dropdown>
                    <Dropdown.Toggle
                      variant="link"
                      id="dropdown-user-menu"
                      className="p-0"
                    >
                      <span className="rounded-circle user-profile-pix">
                        <span>{getInitial(userInfo)}</span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href={`${MoodleBaseUrl}/user/profile.php`}>Profile</Dropdown.Item>
                      {isAdmin && <>
                        <Dropdown.Item href="lmsconfig">LMS Configuration</Dropdown.Item>
                        <Dropdown.Item href="mailconfiguration">Mail Configuration</Dropdown.Item>
                        <Dropdown.Item href="usermanagement">User Management</Dropdown.Item>
                        <Dropdown.Item href="adminfaq">Manage FAQ's</Dropdown.Item>
                        {/* <Dropdown.Item href="urlconfiguration">URL Configuration</Dropdown.Item> */}


                      </>
                      }
                      {/* change password components */}
                      <Dropdown.Item onClick={() => setShowModal(true)}>Change Password</Dropdown.Item>
                      <ChangePassword showModal={showModal} setShowModal={setShowModal} />
                      <Dropdown.Item href={`${config.OAUTH2_URL}/logout`}> Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <button className="navbar-toggler aabtn d-block d-md-none px-1 my-1 border-0" data-toggler="drawers" data-action="toggle" data-target="theme_healthcare-drawers-primary">
                    <span className="navbar-toggler-icon"></span>
                    <span className="sr-only">Toggle sidebar</span>
                  </button>
                </div>}
            </div>
          </nav>
        </div>}
      {/* header end  */}

      {/* side bar start */}
      <div id="globalContent" className="global-container">
        {showHeaderFooter &&<SidebarContent/>}
        {/* side bar end  */}
        {/* for render all pages in between header footer  */}
        {/* This will render the child routes here */}
        <main>
          <Outlet />
        </main>

      </div>
      {/* footer start  */}{showHeaderFooter &&
        <footer id="page-footer" className="page-footer text-center">
          <div className="copyright text-center mb-2">
            Copyright © 2024 Ballistic Learning Pvt Ltd. All rights reserved.
          </div>
        </footer>}
      {/* footer End  */}
    </>
  );
};

export default HeaderFooterLayout;
