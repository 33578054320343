import React, { useMemo } from "react";
import { Table, Button } from "react-bootstrap";
import GlobalErrorAlert from "../../globals/GlobalErrorFunction";

interface FAQItem {
  id?: number | null;
  moodleconfigkey: string;
  moodleconfigvalue: string | null;
}

interface AddConfigTableProps {
  faqData: FAQItem[];
  isLoading: boolean;
  error: string;
  handleEdit: any;
  // handleDelete: any;
  // pageNumber: number;
}

function AddConfigTable({
  faqData,
  isLoading,
  error,
  handleEdit,
  // handleDelete,
  // pageNumber,
}: AddConfigTableProps) {




  // Transform `faqData` into a structure suitable for rendering
  const tableData = faqData.map((item, index) => ({
    // sno: pageNumber * 10 + index + 1, // Serial number based on pagination
    sno: index+1,
    ...item,
  }));

  // Define table columns
  const columns = useMemo(
    () => [
      { Header: "S.No", accessor: "sno" },
      { Header: "Name", accessor: "label" },
      { Header: "Url", accessor: "moodleconfigvalue" },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }: { row: any }) => (
          <div className="d-flex gap-2">
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleEdit(row)}
            >
              Edit
            </Button>
            {/* <Button
              variant="danger"
              size="sm"
              onClick={() => handleDelete(row)}
              // disabled={!row.original.id}
            >
              Delete
            </Button> */}
          </div>
        ),
      },
    ],
    [handleEdit]
  );

  return (
    <div>
      <Table responsive className="generaltable table-dark-header">
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th key={index}>{col.Header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={columns.length} className="text-center">
                Loading...
              </td>
            </tr>
          ) : error ? (
            <tr>
              <td colSpan={columns.length}>
                <GlobalErrorAlert error={error} holdFlag={true} />
              </td>
            </tr>
          ) : tableData.length > 0 ? (
            tableData.map((row: any, index: number) => (
              <tr key={index}>
                {columns.map((col, colIndex) => (
                  <td key={colIndex}>
                    {col.accessor === "action"
                      ? col.Cell?.({ row })
                      : row[col.accessor as keyof typeof row] ?? "-"}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="text-center">
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default AddConfigTable;
