import "./style.scss";
import View from "./view";
import { TConfigItem } from "./typescript";
import { useCallback, useEffect, useState } from "react";
import { getData, postData } from "../../adapters/coreservices";

const MailTempelateConfiguration: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [apiStatus, setApiStatus] = useState<string>("");
  const [tabTitles, setTabTitles] = useState<string[]>([]);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [initialSubject, setInitialSubject] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState<string>("Signup Email");
  const [mailConfigData, setMailConfigData] = useState<TConfigItem[]>([]);
  const [getConfigApiStatus, setGetConfigApiStatus] = useState<string>("");
  const [initialDescription, setInitialDescription] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<string>("en");
  const [apiError, setApiError] = useState<any>({});

  
    useEffect(() => {
      let endPoint = `/config?languageCode=${selectedValue}`;
      setGetConfigApiStatus("started");
      getData(endPoint, {}).then((res: any) => {
        if (res.data !== "" && res.status === 200) {
          setMailConfigData(res.data);
          const tabTitlesList:any = [
            ...new Set(res.data.map((item: TConfigItem) => item.tabTitle)),
          ];
          setSelectedTab(res.data && res.data[0]?.tabTitle)
        setTabTitles(tabTitlesList);
      }
      setGetConfigApiStatus("finished");
    })
    .catch((err:any) => {
      setApiError(err);
      console.log(err);
    });
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      if (selectedTab !== "" && mailConfigData.length > 0) {
        setLoading(true);
        let selectedConfigList = mailConfigData
          .filter((item) => item.tabTitle === selectedTab)
          .map((el) => el.config);

        try {
          setApiStatus("started");
          let endPoint = `/config?languageCode=${selectedValue}`;
          const res = await postData(endPoint, selectedConfigList);
          if (res.data !== "" && res.status === 200) {

          setApiError({});
            let subject = "";
            let description = "";
            res.data.forEach((item: any) => {
              const config = item.configKey.substring(
                item.configKey.lastIndexOf("_") + 1
              );
              if (config === "subject") {
                subject = item.configValue;
              } else {
                description = item.configValue;
              }
            });
            setInitialSubject(subject);
            setInitialDescription(description);
            setLoading(false);
          }
          setApiStatus("finished");
        } catch (error) {
          console.error("Error fetching initial values:", error);
          setApiStatus("finished");
          setLoading(false);
          setApiError(error);
        }
      }
    };

    fetchData();
  }, [selectedTab, refreshData, mailConfigData, selectedValue]);

  const toggleRefresh = () => {
    setRefreshData(!refreshData);
  };

  const languageSelectHandler = useCallback((e: { target: { value: any; }; }) => {
    const getLanguageCode = e.target.value
    setSelectedValue(getLanguageCode);
  }, [])

  return (
    <>
      <View
        apiError={apiError}
        setApiError={apiError}
        loading={loading}
        apiStatus={apiStatus}
        tabTitles={tabTitles}
        selectedValue={selectedValue}
        mailConfigData={mailConfigData}
        initialSubject={initialSubject}
        getConfigApiStatus={getConfigApiStatus}
        initialDescription={initialDescription}
        toggleRefresh={toggleRefresh}
        setSelectedTab={setSelectedTab}
        languageSelectHandler={languageSelectHandler}
      />
    </>
  );
};

export default MailTempelateConfiguration;
