import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { postData } from '../../../adapters/coreservices';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../widgets/pageTitle';
import GlobalErrorAlert from '../../../globals/GlobalErrorFunction';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation(); // For translations
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertMsg, setAlertMsg] = useState({ message: '', alertBoxColor: '' });
  const [apierror, setApierror] = useState("");
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t('validation.invalidEmail'))
        .required(t('validation.requiredEmail')),
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleFormData(values, { setSubmitting });
    },
  });

  const handleFormData = (values: any, { setSubmitting }: any) => {
    setApierror('')
    postData('/public/forgotpassword', values, undefined, false)
      .then((res: any) => {
        if (res.status === 200) {
          setSubmitting(false);
        Swal.fire({
          timer: 4000,
          width: "25em",
          color: "#666",
          icon: "success",
          background: "#e7eef5",
          showConfirmButton: false,
          text:t('forgotPassword.successMessage')
        });
        setTimeout(() => {
          navigate("/"); 
        }, 4000);
      }
      })
      .catch((err: any) => {
        // Check if the error is a 400 and display the error message
      setApierror(err)
        setSubmitting(false); // Allow submit button to be re-enabled
      });
  };
  
  return (
    <>
    <div className="contentarea-wrapper">
      {/* <h2 className="page-title db-title">{t('forgotPassword.title')}</h2> */}
      <PageTitle pageTitle={t('forgotPassword.title')} gobacklink="/" />
      <div className="line-lessmargin"></div><br/>
      {/* Alert Message */}
    {Object.keys(apierror).length > 0 && <GlobalErrorAlert error = {apierror}  holdFalg={true}/>}

      <form onSubmit={formik.handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">{t('forgotPassword.emailLabel')}</label>
          <input
            id="email"
            name="email"
            type="email"
            className="form-control inputWidth"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-danger">{formik.errors.email}</div>
          ) : null}
        </div>
        <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? t('forgotPassword.sending') : t('forgotPassword.sendLink')}
        </button>
      </form>
    </div>
    </>
  );
};

export default ForgotPassword;
