import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import AddFaqTopicModal from './AddFaqTopicModal';
import AddFaqTopic from './AddTopicTable';
import { deleteData, getData } from '../../../../adapters/coreservices';
import PageTitle from '../../../../widgets/pageTitle';
import Swal from 'sweetalert2';
import DeleteAlert from '../../../../widgets/alert/deleteAlert';
import BuildPagination from '../../../../widgets/pagination';
import { pagination } from '../../../../utils/pagination';
import CommonFilter from '../../../../globals/filters/commonFilter';

interface FAQ {
  id: number;
  question: string;
  answer: string;
  published: boolean;
}

interface FAQItem {
  id: number;
  name: string;
  published: boolean;
  faqs: FAQ[];
}

interface FAQData {
  pager: {
    totalPages: number;
    totalElements: number;
  };
  items: FAQItem[];
}

function AddFaqTopicIndex() {
  const { t } = useTranslation();

  const [filterUpdate, setFilterUpdate] = useState({
    pageNumber: 0,
    pageSize: pagination.PERPAGE,
    categoryId:"" 
  });

  const dummyData: FAQData = {
    pager: { totalPages: 0, totalElements: 0 },
    items: [],
  };

  const [faqData, setFaqData] = useState<FAQData>({ 
    pager: { totalPages: 0, totalElements: 0 }, 
    items: []
  });
  const [isLoading, setIsLoading] = useState("false");
  const [error, setError] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState<FAQItem | null>(null);
  const [moodlaStatus, setmoodlaStatus] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState<number | null>(null);
  const [dropdownData, setDropdownData] = useState<FAQData>({ 
    pager: { totalPages: 0, totalElements: 0 }, 
    items: []
  });


  useEffect(() => {
    setIsLoading("true");
    getData("https://api.saral.ballisticlearning.com/core-service/api/v1/faq/get-all-category?pageNumber=0&pageSize=1000",)
      .then((result) => {
        if (result.data && result.status === 200) {
          setDropdownData(result.data);
        }
        setIsLoading("false");
      }) .catch((err) => {
        setError(err)
      });
  }, []);

  useEffect(() => {
    setIsLoading("true");
    getData("https://api.saral.ballisticlearning.com/core-service/api/v1/faq/get-all-category",filterUpdate)
      .then((result) => {
        if (result.data && result.status === 200) {
          setFaqData(result.data);
        }
        setIsLoading("false");
      })
      .catch((err) => {
        setError(err || 'An error occurred.');
        setFaqData(dummyData);
        setIsLoading("false");
      });
  }, [filterUpdate]);


  const handleTopicAdded = () => {
    setFilterUpdate({ ...filterUpdate }); // Refresh data
  };

  const handleEdit = (data: FAQItem) => {
    setEdit(data);
    setShowModal(true);
    setmoodlaStatus("edit");
  };
  useEffect(() => {
    if (!showModal) {
      setEdit(null);
    }
  }, [showModal])
  
// Handle Delete
const handleDelete = (id: number) => {
  setCurrentDeleteId(id);
  setShowDeleteModal(true);
};


const deleteFAQ = async () => {
  try {
    const res = await deleteData(`faq/category/${currentDeleteId}`);
      if (res.data && res.status === 200) {
        handleTopicAdded()
        Swal.fire({
          timer: 3000,
          width: "25em",
          color: "#666",
          icon: "success",
          background: "#e7eef5",
          showConfirmButton: false,
          text: t('common.topicDeleted')
        });
        setShowDeleteModal(false);
        setCurrentDeleteId(null);
      }
    } catch (err:any) {
      console.error(err);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: err?.response?.data.message,
      });
      setShowDeleteModal(false);
      setCurrentDeleteId(null);
    }
  };

  const deleteActionResponse = (action: string) => {
    if (action === t('common.yes')) {
      deleteFAQ();
    } else {
      setShowDeleteModal(false);
    }
  };

  const newPageRequest: (pageRequest: number) => void = (pageRequest: number) => {
    setFilterUpdate({ ...filterUpdate, pageNumber: pageRequest });
  };


  const fields:any = [
    { name: 'published', label: 'Published', placeholder: 'All', type: 'select', options: [{ value: "true", name: 'True' }, { value: "false", name: 'False' }] }
  ];

  const handleFilterUpdate = (update: any) => {
    setFilterUpdate(prev => ({ ...prev, ...update }));
  };

  return (
    <div className="contentarea-wrapper">
      {/* <h2 className="page-title">{t('FAQ.adminFaqTitle')}</h2> */}
      <PageTitle 
        pageTitle={t("FAQ.addFaqTopicTitle")} 
        gobacklink="/adminfaq" />
      
      
      <div className='action-btn pb-2 text-md-end'>
      <Button
        variant="primary"
        onClick={() => { setShowModal(true);}}>
           Add Topic
      </Button>
      </div>
      
      <CommonFilter updateFilters={handleFilterUpdate} apiStatus={isLoading} fields={fields} />


      <AddFaqTopic
        faqData={faqData.items}
        isLoading={isLoading}
        error={error}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        pageNumber={filterUpdate.pageNumber}
      />

       <BuildPagination
          getrequestedpage={newPageRequest}
          activepage={filterUpdate.pageNumber}
          totalpages={faqData.pager.totalPages}
        />

      <AddFaqTopicModal
        showModal={showModal}
        setShowModal={setShowModal}
        onTopicAdded={handleTopicAdded}
        edit={edit}
        moodlaStatus={moodlaStatus}
      />

          <DeleteAlert
          show={showDeleteModal}
          modalHeading="Question"
          onHide={() => setShowDeleteModal(false)}
          deleteActionResponse={deleteActionResponse}
        />
    </div>
  );
}

export default AddFaqTopicIndex;
