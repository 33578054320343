import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Types for dynamic fields and form values
export type FilterField = {
  name: string;
  label: string;
  placeholder?: string;
  type: string; // For example: 'text', 'email', 'select', etc.
  options?: string[]; // Options for 'select' type
};

export type TInitialValues = {
  [key: string]: string; // Dynamic key-value pairs for form values
};

export type TGuestFilter = {
  updateFilters: (filters: { [key: string]: string }) => void;
  apiStatus: string;
  fields: FilterField[]; // Array of fields for dynamic filter rendering
};

// Config for debounce timeout
export const filterConfig = {
  timeoutNumber: 500, // Timeout duration in milliseconds
};

// Button for showing loading state
export const FiltersLoadingBtn = (apiStatus: string) => {
  if (apiStatus === "loading") {
    return (
      <Button variant="outline-secondary" disabled>
        Loading...
      </Button>
    );
  }
  return null; // No button if not loading
};

const CommonFilter: React.FC<TGuestFilter> = ({ updateFilters, apiStatus, fields }) => {
  const [timeoutId, setTimeoutId] = useState<any>(null);
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState<TInitialValues>({});

  // Construct initial values when fields change
  useEffect(() => {
    const values = fields.reduce((acc, field) => {
      acc[field.name] = ""; // Default value is an empty string
      return acc;
    }, {} as TInitialValues);
    setInitialValues(values);
  }, [fields]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (timeoutId) clearTimeout(timeoutId); 
      updateFilters(values);
    },
    onReset: () => {
      if (timeoutId) clearTimeout(timeoutId);
      formik.setValues(initialValues);
      updateFilters(initialValues);
    },
  });

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;

    formik.setFieldValue(name, value);

    if (timeoutId) clearTimeout(timeoutId);

    const newTimeoutId: NodeJS.Timeout = setTimeout(() => {
      updateFilters({ ...formik.values, [name]: value });
    }, filterConfig.timeoutNumber);

    setTimeoutId(newTimeoutId);
  };

  return (
    <React.Fragment>
      <div className="sms-custom-filter mt-3 mb-3">
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <Row className="align-items-baseline">
            {fields.map((field, index) => (
              <Col key={index} md="auto">
                <label htmlFor={field.name}>
                  {field.label}
                  {/* Render dropdown (select) if type is 'select' */}
                  {field.type === 'select' ? (
                    <select
                      className="form-control"
                      id={field.name}
                      name={field.name}
                      onChange={handleFilterChange}
                      value={formik.values[field.name] || ""}
                    >
                      {field.placeholder && <option value="">{field.placeholder}</option>}
                      {field.options?.map((option: any, i) => (
                        <option key={i} value={option.value}
                        className={formik.values.categoryId === option.value ? "font-weight-bold" : ""}>
                        {option.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      className="form-control"
                      id={field.name}
                      name={field.name}
                      type={field.type}
                      placeholder={field.placeholder}
                      onChange={handleFilterChange}
                      value={formik.values[field.name] || ""}
                    />
                  )}
                </label>
              </Col>
            ))}
            <Col md="auto">
              {FiltersLoadingBtn(apiStatus)}
              <Button variant="info" type="reset" onClick={formik.handleReset}>
                {t('common.reset')}
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    </React.Fragment>
  );
};


export default CommonFilter;
