import React from "react";
import Browser from "./browser";

// Define TProps to ensure all props in commonProps are properly typed
export interface TProps {
  apiError:any;
  setApiError:any;
  loading: boolean;
  apiStatus: string;
  tabTitles: string[];
  selectedValue: string;
  mailConfigData: any[];  // Adjust type according to structure of each item
  initialSubject: string;
  getConfigApiStatus: string;
  initialDescription: string;
  toggleRefresh: () => void;
  setSelectedTab: (tab: string) => void;
  languageSelectHandler: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const View: React.FC<TProps> = (props) => {
  // commonProps aggregates properties to pass to Browser
  const commonProps = {
    apiError:props.apiError,
    setApiError:props.setApiError,
    loading: props.loading,
    apiStatus: props.apiStatus,
    tabTitles: props.tabTitles,
    selectedValue: props.selectedValue,
    mailConfigData: props.mailConfigData,
    initialSubject: props.initialSubject,
    getConfigApiStatus: props.getConfigApiStatus,
    initialDescription: props.initialDescription,
    toggleRefresh: props.toggleRefresh,
    setSelectedTab: props.setSelectedTab,
    languageSelectHandler: props.languageSelectHandler
  };

  return (
    <React.Fragment>
      {/* Use Browser component, passing down commonProps */}
      <Browser commonProps={commonProps} />
    </React.Fragment>
  );
};

export default View;
