import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { postData } from '../../../adapters/coreservices'; // Assuming this function sends the API request
import { useSelector } from 'react-redux';
import GlobalErrorAlert from '../../../globals/GlobalErrorFunction';

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState<{ message: string; alertBoxColor: string }>({
    message: '',
    alertBoxColor: '',
  });
  const [requestToken, setRequestToken] = React.useState<string | null>(null);
  const isLoggedIn = useSelector((state: any) => state.user.loggedIn);
  const [apiError, setApiError] = useState("");


  useEffect(() => {
    // Extract token from URL query params
    const urlParams = new URLSearchParams(location.search);
    setRequestToken(urlParams.get("token"));
  }, [location]);

  // Handle form data submission
  const handleFormData = (values: any, { setSubmitting, resetForm }: any) => {
  const endPoint = `/public/resetpassword?token=${requestToken}`;
  // Directly use `newPassword` for the API request while maintaining the original field name in the form
  postData(endPoint, {
    newPassword: values.password, // Map 'password' to 'newPassword'
    confirmPassword: values.confirmPassword, // Keep 'confirmPassword' as is
  }, undefined,false)
    .then((res: any) => {
      if (res.status === 200) {
        setAlertStatus(true);
        resetForm();
        setAlertMsg({
          message: "Password has been successfully reset.", // Hardcode a success message here
          alertBoxColor: "alert-success", // Success color
        });
        setSubmitting(false);
        setTimeout(() => {
          navigate("/"); // Redirect after success
        }, 2000);
      } else {
        // Handle unexpected status
        setAlertStatus(true);
        resetForm();
        setSubmitting(false);
        setAlertMsg({
          message: "An unexpected error occurred.", // Hardcode an error message for unexpected responses
          alertBoxColor: "alert-danger", // Error color
        });
      }
    })
    .catch((err) => {
      console.log(err);
      setApiError(err);

      setSubmitting(false);
    });
};


  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, t("validation.passwordMinLength"))
        .required(t("validation.passwordRequired")),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], t("validation.passwordsMustMatch"))
        .required(t("validation.passwordConfirmRequired")),
    }),
    onSubmit: handleFormData, // Use handleFormData for form submission
  });

  return (
    <>

    <div className="contentarea-wrapper">
      <h2 className="page-title db-title">{t("resetPassword.title")}</h2>
      <div className="line-lessmargin"></div><br/>

      {/* Display alert message based on submission result */}
      {alertStatus && (
        <div className={`alert ${alertMsg.alertBoxColor}`}>
          {alertMsg.message}
        </div>
      )}
        { isLoggedIn && <div className="alert alert-danger"> You have to be logged out to reset password. </div>}
        {Object.keys(apiError).length > 0 ? ( 
                    <GlobalErrorAlert error={apiError} holdFalg={true}/>
         ) : null} 

      {/* Password reset form */}
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            {t("resetPassword.newPassword")}
          </label>
          <input
            id="password"
            name="password"
            type="password"
            className="form-control inputWidth"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            disabled={isLoggedIn}
            />
          {formik.touched.password && formik.errors.password && (
            <div className="text-danger">{formik.errors.password}</div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="confirmPassword" className="form-label">
            {t("resetPassword.confirmPassword")}
          </label>
          <input
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            className="form-control inputWidth"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            disabled={isLoggedIn}
            />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className="text-danger">{formik.errors.confirmPassword}</div>
          )}
        </div>

        <button
          type="submit"
          className="btn btn-primary"
          disabled={formik.isSubmitting}
          >
          {formik.isSubmitting ? t("resetPassword.submitting") : t("resetPassword.resetPassword")}
        </button>
      </form>
    </div>
    </>
  );
};

export default ResetPassword;
